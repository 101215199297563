import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { isIOS } from 'react-device-detect'
import _ from 'lodash'
import GalleryCarousel from './GalleryCarousel'
import OverlayContainer from './OverlayStyle'
import { setIsDialogOpen, setIsPopupOpen, setMediaForPopup } from '../../actions/mediaPopupAction'

const ModalStyle = styled.div`
    width: 100%;
    z-index: 9999 !important;

    .modal {
        display: block;
        background-color: #000000e6;
    }
    
    .single-media {
        padding-right: 50px;
        padding-left: 50px;
    }

   video {
        max-width: 100% !important;
    }

    img {
        object-fit: contain;
    }

    .media-popup {
        height: 100vh;
    }

    video, img {
        max-height: var(--app-height);
    }

    .content {
        height: 100% !important;
        overflow-y: hidden;
    }

    @media(max-width: 767px) {
        .single-media {
            padding-right: 0;
            padding-left: 0;
        } 
        
        video, img {
            max-height: ${props => props.isIOS ? 'var(--app-height)' : '90vh'};
        }

        .media-popup {
            height: ${props => props.isIOS ? 'var(--app-height)' : '90vh'} !important;
        }
    }
`

const CloseButton = styled.button`
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    border-radius: 77%;
    height: 30px;
    width: 30px;
    right: 10px; 
    position: absolute; 
    top: 20px;
    cursor: pointer;
    z-index: 10;
    display: inherit;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

function MediaPopUpDialog(props) {
    const { media } = props.mediaPopup
    useEffect(() => {
        appHeight()
        window.addEventListener('resize', appHeight)
        window.addEventListener('mousedown', onClickOutSideContent)
        const close = (event) => {
            (event.keyCode === 27) && props.onCloseDialog()
        }
        window.addEventListener('keydown', close)
        return () => {
            window.removeEventListener('keydown', close)
            window.removeEventListener('mousedown', onClickOutSideContent)
        }
    }, [])


    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    const onClickOutSideContent = (e) => {
        const target = e.target
        const allowedClasses = [
            'content-modal',
            'gallery-content-overlay',
            'fa-chevron-left',
            'fa-chevron-right',
            'sc-frDJqD',
            'sc-hmzhuo'
        ]

        let node = target

        while (node) {
            if (node.classList && allowedClasses.some(cls => node.classList.contains(cls))) {
                return // Click is inside, so do nothing
            }
            node = node.parentElement
        }

        // Click is outside, close the dialog
        props.onCloseDialog()
    }

    return <ModalStyle className='media-popup' isIOS={isIOS}>
        <div className='modal fade show'>
            <div className="modal-dialog modal-fullscreen" style={{ height: '100%', width: '100%', margin: '0%', maxHeight: '100%', maxWidth: '100%' }}>
                <div className="modal-content" style={{ backgroundColor: 'transparent' }}>
                    <div className="modal-body" style={{ backgroundColor: 'transparent', margin: '0', padding: '0' }}>
                        <CloseButton
                            className='close'
                            content_color={props.auth.appSettings.content_color}
                            content_font_color={props.auth.appSettings.content_font_color}
                            onClick={() => props.onCloseDialog()}
                        />
                        <div className={classNames('d-flex flex-column justify-content-center align-items-center', { 'content media-popup single-media': media.length === 1 })}>
                             <GalleryCarousel media={media} currentIndex={0} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalStyle>
}

MediaPopUpDialog.propTypes = {
    auth: PropTypes.object.isRequired,
    setIsPopupOpen: PropTypes.func,
    setIsDialogOpen: PropTypes.func.isRequired,
    setMediaForPopup: PropTypes.func.isRequired,
    mediaPopup: PropTypes.object.isRequired,
    onCloseDialog: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    mediaPopup: state.mediaPopup
})

export default connect(mapStateToProps,
    {
        setIsPopupOpen,
        setIsDialogOpen,
        setMediaForPopup
    }
)(withRouter(MediaPopUpDialog))
