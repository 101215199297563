import {
    IS_POPUP_OPEN,
    IS_DIALOG_OPEN,
    SET_MEDIA_FOR_POPUP
} from './types'

export const setIsPopupOpen = (payload) => dispatch => {
    dispatch({ type: IS_POPUP_OPEN, payload })
}

export const setIsDialogOpen = (payload) => dispatch => {
    dispatch({ type: IS_DIALOG_OPEN, payload })
}

export const setMediaForPopup = (payload) => dispatch => {
    dispatch({ type: SET_MEDIA_FOR_POPUP, payload })
}

