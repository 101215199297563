import {
    IS_POPUP_OPEN,
    IS_DIALOG_OPEN,
    SET_MEDIA_FOR_POPUP
} from '../actions/types'

const initialState = {
    isPopupOpen: false,
    isDialogOpen: false,
    media: [],
    index: -1
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IS_POPUP_OPEN:
            return {
                ...state,
                isPopupOpen: action.payload
            }
        case IS_DIALOG_OPEN:
            return {
                ...state,
                isDialogOpen: action.payload
            }
        case SET_MEDIA_FOR_POPUP: {
            return {
                ...state,
                media: action.payload
            }
        }
        default:
            return state
    }
}
